<template>
	<div class="row">
			<div id="content" class="col-sm-12">
				<div class="page-login">
				
					<div class="account-border">
          <div class="well" style="min-height: auto !important;">										
                
						<div class="row">
              
							<div class="col-sm-12 customer-login">
							<h2>Open Dispute</h2>
              
              <table class="table ">
                <tbody>
                  <tr>
                      
                    <td class="text-center col-md-1"><a><img width="100px" :src="order[0].simple_product_id.images[0].image" alt="Aspire Ultrabook Laptop" title="Aspire Ultrabook Laptop" class="img-thumbnail"></a></td>
                    
                    <td class="text-left col-md-4" >
                      
                      <div class="row">
                        <div class="col-md-12" style="font-size:14px; margin-bottom:5px;">								
                          <a><b>{{ order[0].simple_product_id.product_id.product_name }} / {{ order[0].simple_product_id.attributes[0].attribute_value_id.lable }} / {{  order[0].simple_product_id.attributes[1].attribute_value_id.lable }}</b></a>
                        </div>
                      </div>		

                      <div class="row">
                        <div class="col-md-12">								
                          Order ID : <B>{{ order[0].order_env_number }}</B>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">								
                          Date : {{ order[0].date }}
                        </div>
                      </div>

                    </td>

                    <td class="text-right"  style="vertical-align: middle;"><B>{{ order[0].qty }}</B> Items</td>
                    <td class="text-right"  style="vertical-align: middle;">Sub Total &nbsp; <B>Rs:</B>{{ order[0].subTotal }}</td>
                    <td class="text-right"  style="vertical-align: middle;">Shipping and Handling fee &nbsp; <B>Rs:</B>{{ order[0].delivery_charge}}</td>
                    <td class="text-right"  style="vertical-align: middle;">Total &nbsp; <B>Rs:</B>{{ order[0].total_charge}}</td>
                  </tr>
                </tbody>
              </table>
              <hr/>

              <div class="row">
                  <div class="col-sm-6">
                      <label class="control-label" for="input-password">01. Did You received your Item ?</label><br/>

                      <label class="radio-inline">
                        <input type="radio" :value="false" name="newsletter1" checked="checked" @click="handdleReceivedTrue()" >Yes
                      </label>
                      <label class="radio-inline">
                        <input type="radio" :value="false" name="newsletter1" checked="checked" @click="handdleReceivedFalse()">No
                      </label>

                  </div>
              </div><br/>
              <div class="row">
                  <div class="col-sm-6">
                      <label class="control-label" for="input-password">02. Refund Method ?</label><br/>

                      <label class="radio-inline">
                        <input type="radio" :value="true" name="newsletter" checked="checked" @click="handdleRefundMethod1()" >Bank
                      </label>
                      <label class="radio-inline">
                        <input type="radio" :value="true" name="newsletter" checked="checked" @click="handdleRefundMethod2()">Voucher
                      </label>

                      <div v-if="isDisable === true">
                      <label class="radio-inline">
                        <lable>Account Number:</lable>
                        <input type="text" class="form-control" v-model="account_number"/>
                      </label><br/>
                      <label class="radio-inline">
                        <lable>Bank:</lable>
                        <input type="text" class="form-control" v-model="bank"/>
                      </label><br/>
                      <label class="radio-inline">
                        <lable>Branch:</lable>
                        <input type="text" class="form-control" v-model="branch"/>
                      </label>
                      </div>

                  </div>
              </div><br/>
              <div class="row">
                  <div class="col-sm-6">
                       <div class="form-group required">
                        <label>03. Please select your Reason for Dispute</label>
                            <select class="form-control" @change="changeReason($event.target.value)">
                            <option value=""> --- Please Select --- </option>
                            <option  v-for="(item, val) in disputeArray" :key="val" :value="item._id">{{ item.reason}}</option>
                            </select>
                        </div>
                  </div>
              </div><br/>
              <div class="row">
                  <div class="col-sm-6">
                       <div class="form-group required">
                        <label>04. Please describe your Reason</label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="discribe_reason"></textarea>
                        </div>
                  </div>
              </div><br/>
              <div class="row">
                  <div class="col-sm-6">
                      <div class="form-group required">
                          <label>05. Attachments</label>
                          <div style="margin-top: 5px; margin-bottom:5px;" v-if="images != ''" >
                            <img width="100px" :src="images" alt="" title="" class="img-thumbnail">
                          </div>
                          <div style="margin-top: 5px; margin-bottom:5px;" v-if="uploading">
                            <img width="100px" src="assets/image/product/loading.gif" alt="" title="" class="img-thumbnail">
                          </div>
                          
                          <input type="file" @change="getFile"/>
                      </div>
                  </div>
              </div>
              <hr/>
              
              <div class="row">
                <div class="col-sm-12">
                    <div class="form-group text-right">
                    <button class="btn btn-primary" @click="submit()">Open Dispute</button>
                    </div>
                </div>
            </div>

            </div>

            </div>
          </div>
          </div>

        </div>
      </div>
  </div>  
</template>
<script>
import mixinOrder from "@/mixins/buyer/APIOrder"
export default {
  mixins: [mixinOrder],
  data: function () {
    return {
      breadcrumb: [
        {
          text: "Return Order List",
          href: "/buyer-return-orders",
          active: false
        },
        {
          text: "Return Order Configure",
          href: "#",
          active: true
        }
      ],
      isDisable: false,
      order: {},
      disputeArray: [],
      receivedTrue: true,
      receivedFalse: true,

      received_product: "",
      refund_method: 2,
      discribe_reason: "",
      dispute_reason: "",
      images: "",


      fileUploadFormData: new FormData(),
      uploading: false,
      image: "",

      isVisible: false,

      account_number: "",
      bank: "",
      branch: ""
    }
  },
  computed: {
    oid() {
      let url = window.location.href.split("buyer-return-order-configure/");
      return url[1];
    }
  },
  created(){
    this.handleBreadcrumb();
    this.getOrderDetails();
    this.getOrderDisputeDetails();
  },
  methods: {
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    getOrderDetails: async function() {
      try {
        let responce = await this.getOrderById(this.oid);
        console.log(responce)
        
          if (responce.order_line_status === 1) {
            responce.order_line_status = "Pending";
          } else if (responce.order_line_status === 10) {
            responce.order_line_status = "Processing";
          } else if (responce.order_line_status === 20) {
            responce.order_line_status = "Ready to Pickup";
          } else if (responce.order_line_status === 30) {
            responce.order_line_status = "Shipped";
          } else if (responce.order_line_status === 40) {
            responce.order_line_status = "Delivered";
          } else if (responce.order_line_status === 50) {
            responce.order_line_status = "Fail to Delivered";
          } else if (responce.order_line_status === 60) {
            responce.order_line_status = "Return to store";
          } else if (responce.order_line_status === 70) {
            responce.order_line_status = "Accept Return";
          }
          responce.subTotal = responce.unit_price * responce.qty;
          this.order = [responce];
      
      } catch (error) {
        console.log(error); 
      }
    },
    getOrderDisputeDetails: async function() {
       try {
        let responce = await this.disputeDetails();
        this.disputeArray = responce;
      } catch (error) {
        console.log(error); 
      }
    },
    handdleReceivedTrue: function() {
      this.received_product = true;
    },
    handdleReceivedFalse: function() {
      this.received_product = false;
    },
    handdleRefundMethod1: function() {
      this.refund_method = 1;
      this.isDisable = true;
    },
    handdleRefundMethod2: function() {
      this.refund_method = 2;
      this.isDisable = false;
    },
    changeReason: function(id) {
      this.dispute_reason = id;
    },
    getFile: async function(eventf) {
    //   this.isLoading = true;
      this.uploading = true;
      await this.fileUploadFormData.append("image", eventf.target.files[0]);
      var tgt = eventf.target || window.event.srcElement;
      let files = tgt.files;

      let self = this;
      if (FileReader && files && files.length) {
        var fr = new FileReader();
        fr.onload = function() {
          self.image = fr.result;
        };
        fr.readAsDataURL(files[0]);
      }

      try {
        let formData = await this.fileUploadFormData;
        let response = await self.putReturnOrderImage(formData);
        this.uploading = false;
        this.images = response.image;
         
        // this.$swal.fire({
        //   position: 'center',
        //   type: 'success',
        //   title: 'Submit Success',
        //   showConfirmButton: false,
        //   timer: 1500
        // })
        // this.isLoading = false;
        this.fileUploadFormData = new FormData();
      } catch (error) {
        this.uploading = false;
        this.$swal.fire({
          position: 'center',
          type: 'error',
          title: 'Submit Error',
          showConfirmButton: false,
          timer: 1500
        })
      }
    },
    submit: async function() {
      try {
        let object = {}
        object.received_product = this.received_product;
        object.refund_method = this.refund_method;
        object.discribe_reason = this.discribe_reason;
        object.dispute_reason = this.dispute_reason;
        object.order_line_id = this.order[0]._id;
        object.images = this.images;
        if (this.refund_method === 1) {
          object.account_number = this.account_number;
          object.bank = this.bank;
          object.branch = this.branch;
        }

        let responce = await this.postReturnOrder(object);
        console.log(responce);
        
        this.$swal.fire({
          position: 'center',
          type: 'success',
          title: 'Submit Success',
          showConfirmButton: false,
          timer: 1500
        })

        this.$router.push({ name: "Buyer Return Orders" });

      } catch (error) {
        this.$swal.fire({
          position: 'center',
          type: 'error',
          title: 'Submit Error',
          showConfirmButton: false,
          timer: 1500
        })
      }
    }
  }
}
</script>
<style>
.radiocontainer input:checked ~ .checkbackground:after {
display: block;
}

.radiocontainer .checkbackground:after {
top: 0px;
left: 0px;
width: 100%;
height: 100%;
border-radius: 5%;
background:#0096aa75;
border:1px solid #000000;
}


.checkbackground {
position:relative;
border-radius: 5%;
/* border:1px solid #666666; */
background-color: #eee;
padding: 8px 10px;
margin-right:10px;
}

.checkbackground:after {
content: "";
position: absolute;
display: none;
}
</style>

